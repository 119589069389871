export enum SupportedScope {
    DISPOSAL = 'DISPOSAL',
    PARKING = 'PARKING',
    GAS_STATIONS = 'GAS_STATIONS',
    NOTIFICATIONS = 'NOTIFICATIONS',
    ALL = 'ALL',
    ROUTES = 'ROUTES',
    GASTRO = 'GASTRO',
    NEWS = 'NEWS',
    EVENTS = 'EVENTS',
    TICKETING = 'TICKETING',
    TICKETING_HI = 'TICKETING_HI',
    TICKETING_EH = 'TICKETING_EH',
    TICKETING_VFV = 'TICKETING_VFV',
    TICKETING_HG = 'TICKETING_HG',
    TICKETING_JO = 'TICKETING_JO',
    TICKETING_VHSBREMEN = 'TICKETING_VHSBREMEN'
}
