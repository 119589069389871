import Parseable from '@/misc/Parseable';

export default class User extends Parseable {
    public accountId?: string;
    public firstName?: string;
    public lastName? : string;
    public scopes?: string[];
    public email?:string;
    public id!:string;

    public static parseFromObject(object: Partial<User>): User {
        const user = new User();

        Object.assign(user, object);
        return user;
    }

    public parseToObject(): Partial<User> {
        const tmp: any = {...this};
        return tmp;
    }
}





